import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PME = () => {
    return (
        <Layout>
            <Seo
                title="Pequeñas Empresas - BIKESAFE"
                description="Con la subida del precio del carburante y la atención puesta en reducir las emisiones perjudiciales, cada vez más personas utilizan la bicicleta para desplazarse, lo que ha generado un aumento de ventas en ciclismo y en el negocio de bicicletas. Esto significa que ofrecer un parking para bicicletas cómodo es una decisión empresarial inteligente."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Pequeñas Empresas
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Bueno para las empresas (un negocio ecológico es bueno para el planeta)</h3>
                        <p className="flow-text">
                        Con la subida del precio del carburante y la atención puesta en reducir las emisiones perjudiciales, cada vez más personas utilizan la bicicleta para desplazarse, lo que ha generado un aumento de ventas en ciclismo y en el negocio de bicicletas. Esto significa que ofrecer un parking para bicicletas cómodo es una decisión empresarial inteligente.
                        </p>
                        <p className="flow-text">
                        Al ofrecer un aparcamiento para bicicletas seguro a sus clientes o empleados, puede mejorar potencialmente las ventas de su empresa, aunque pertenezca a un sector que no esté directamente relacionado con las ventas en ciclismo, así como transmitir una imagen de negocio ecológico. Los ciclistas y las personas que no usen la bicicleta agradecerán su idea "ecológica" al ofrecerles un aparcamiento seguro para bicicletas. El aumento experimentado por el negocio de bicicletas supone una subida en el número de personas que utilizan este medio de transporte, lo que unido a la gran facilidad de trasladarse rápidamente a su tienda o a sus instalaciones en bicicleta le hará ganarse con toda seguridad la fidelidad de estos clientes.
                        </p>
                        <p className="flow-text">
                        Un parking para bicicletas claramente definido evita los conflictos con los peatones y los vehículos estacionados, ayudando a mantener las bicicletas alejadas de barandillas y señales de tráfico, y evitando que impidan el acceso normal a la tienda o representen un obstáculo para los peatones.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/pme.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"El nuevo diseño está funcionando muy bien. Gracias por su ayuda."
                                <strong> Simon Flack, Cycleops (propietario de un comercio), Kent</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PME;